import axios from "axios"
import util from "./util"

// const baseUrl = `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

//   const fixUrls = media => {
//     return {
//       ...media,
//       webViewUrl: media.webViewUrl ? `${baseUrl}${media.webViewUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.webViewUrl,
//       thumbnailUrl: media.thumbnailUrl ? `${baseUrl}${media.thumbnailUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.thumbnailUrl
//     };
//   }


export const getPhotos = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/photos").then(
      response => {
        console.log("photos queried successfully", response)
        resolve(response.data)
      },
      error => {
        console.log("getPhotos ERROR: photos not found", error)
        reject(error)
      }
    )
  })
}

export const findByBranch = async branch => {
  return new Promise(function(resolve, reject) {
    axios.get("/photos/findByBranch/" + branch).then(
      response => {
        console.log("photos queried successfully", response)
        resolve(response.data)
      },
      error => {
        console.log("findByBranch ERROR: photos not found", error)
        reject(error)
      }
    )
  })
}

/**
 *
 * @param {*} photo a story object to be created
 */
export const savePhoto = async photo => {
  return new Promise(function(resolve, reject) {
    axios.post("/photos", photo).then(
      response => {
        console.log("photo created successfully", response)

        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("createPhoto error:", error)
        reject(error)
      }
    )
  })
}

export const deletePhoto = async id => {
  return new Promise(function(resolve, reject) {
    axios.delete("/photos/" + id).then(
      response => {
        console.log("photo " + id + " deleted successfully", response)
        // pass back the deleted story?
        resolve(response)
      },
      error => {
        console.log("deletePhoto error:", error)
        reject(error)
      }
    )
  })
}

export default {
  getPhotos,
  findByBranch,
  savePhoto,
  deletePhoto,
}